import React from 'react'

import Table from './component/Table'

import './App.css'

export default function App() {
  return (
    <Table />
  )
}
